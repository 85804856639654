@charset "UTF-8";
@import "reset";
@import "common";

$beforeFadePos: 30%;

$transition1: all 1.5s .9s;
$transition2: all 1.5s 1.3s;
$slideTransition: all 2s;

.main-visual {

    &__inner {

        .slider {
            width: 100vw;
            height: 100vh;
            position: relative;
        }
    }
}
.slide {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
    transition: $slideTransition;
    &.active {
        opacity: 1;
        z-index: 20;
    }

    &__inner {
        width: 100%;
        height: 100%;
        position: relative;

        .slide-img {
            width: 100%;
            height: 100%;
            position: relative;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;

                &.pc {
                    @include sp{
                        display: none;
    
                    }
                }
                &.sp {
                    display: none;

                    @include sp{
                        display: inline-block;
    
                    }
                }

            }

            // &.gray {

                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: rgba(14, 76, 89, 0.2);
                }
            // }
        }

        .slide-contents {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            @include tabMid {
                @include spWidth;
                left: 50%;
                transform: translateX(-50%);
            }
            
        }

        .slide-contents.slide1 {

            .slide-contents__inner {
                width: 585px;
                margin: 23vh auto 0 12.5vw;

                @include tabMid {
                    margin: 23vh auto 0 0;
                }
                @include sp {
                    width: 78%;
                    margin: 15.5vh auto 0 0;
                }

                .slide-title {
                    color: #fff;
                    @include oswaldBold;
                    font-size: 11rem;
                    margin-bottom: 42px;

                    @include tab {
                        font-size: 8rem;
                    }

                    @include sp {
                        font-size: calc(140vw / 7.5);
                        margin-bottom: 3vh;
                    }

                    @include spSmall {
                        font-size: calc(120vw / 7.5);
                        margin-bottom: 2vh;
                    }
                }


                .slide-sub {
                    color: #fff;
                    @include NotoM;
                    
                    .slide-subtitle {
                        font-size: 2.9rem;
                        margin-bottom: 21px;

                        @include tabMid {
                            font-size: 2rem;
                        }

                        @include sp {
                            line-height: 1.3;
                            margin-bottom: 4vh;
                        }

                        @include spSmall {
                            font-size: 1.7rem;
                            margin-bottom: 2vh;
                        }

                    }

                    .slide-desc {
                        font-size: 1.4rem;
                        line-height: 22px;

                        span {

                            @include spVer {
                                text-align: justify;
                                text-align-last: justify;

                                &:nth-last-child(1) {
                                    text-align-last: auto;
                                }
                            }
                            
                        }

                        @include tabMid {
                            font-size: 1.2rem;
                        }
                        &:last-of-type {
                            margin-bottom: 21px;

                            @include spSmall {
                                margin-bottom: 1.5vh;
                            }
                        }
                    }
                }
            }
        }
        .slide-contents.slide2 {
            
            .slide-contents__inner {
                width: 585px;
                margin: 43vh auto 0 12.5vw;

                @include tabMid {
                    margin: 43vh auto 0 0;
                }

                @include sp {
                    width: 90%;
                    margin: 17vh auto 0 auto;
                    text-align: center;
                }

                .slide-title {
                    color: #000;
                    width: 319px;
                    height: auto;
                    margin-bottom: 32px;

                    @include sp {
                        margin-bottom: calc(40vw / 7.5);
                        width: auto;

                        img {
                            height: calc(60vw / 7.5);
                            transform: translateX(calc(-10vw / 7.5));
                        }
                    }

                }

                .slide-sub {
                    color: #000;
                    @include NotoB;

                    .slide-subtitle {
                        font-size: 2.6rem;
                        margin-bottom: 20px;

                        @include sp {
                            font-size: calc(40vw / 7.5);
                            margin-bottom: calc(5vw / 7.5);
                        }
                    }

                    .slide-desc {
                        font-size: 1.2rem;
                        line-height: 22px;
                        margin-bottom: 16px;

                        @include sp {
                            font-size: calc(20vw / 7.5);
                            margin-bottom: calc(40vw / 7.5);
                        }
                    }
                }

                .btn-box {
                    @include sp {
                        margin: auto;
                    }
                }
            }
        }
        .slide-contents.slide3 {

            @include sp {
                width: 100%;
            }
            .slide-contents__inner {
                width: 585px;
                margin: 38vh auto 0 12.5vw;

                @include tabMid {
                    margin: 38vh auto 0 0;
                }

                @include sp {
                    width: 95%;
                    margin: 17vh auto 0 auto;
                    text-align: center;
                }

                .slide-title {
                    color: #000;
                    width: 319px;
                    height: auto;
                    margin-bottom: 32px;

                    @include sp {
                        margin: 0 auto calc(48vw / 7.5) auto;
                        width: auto;

                        img {
                            width: calc(432vw / 7.5);
                        }
                    }
                }

                .slide-sub {
                    color: #000;
                    @include NotoB;

                    .slide-subtitle {
                        font-size: 2.6rem;
                        margin-bottom: 20px;

                        @include sp {
                            font-size: calc(40vw / 7.5);
                            margin-bottom: calc(35vw / 7.5);
                        }
                    }

                    .slide-desc {
                        font-size: 1.2rem;
                        line-height: 22px;
                        margin-bottom: 16px;

                        @include sp {
                            font-size: calc(24vw / 7.5);
                            margin-bottom: calc(40vw / 7.5);
                        }

                    }
                }

                .btn-box {
                    @include sp {
                        margin: auto;
                    }
                }
            }
        }
        .slide-contents.slide4 {

            .slide-contents__inner {
                width: 585px;
                margin: 32vh auto 0 12.5vw;

                @include tabMid {
                    margin: 25vh auto 0 0;
                }

                @include sp {
                    width: 85%;
                    margin: 15.5vh auto 0 0;
                }

                .slide-type {
                    color: #fff;
                    font-size: 2rem;
                    @include NotoB;
                    margin-bottom: 30px;

                    @include sp {
                        font-size: calc(25vw / 7.5);
                        margin-bottom: 3vh;
                    }
                }

                .slide-title {
                    color: #fff;
                    @include oswaldBold;
                    font-size: 6.6rem;
                    margin-bottom: 73px;

                    @include tab {
                        font-size: 8rem;
                        margin-bottom: calc(50vw / 7.5);
                    }

                    @include sp {
                        font-size: calc(140vw / 7.5);
                        margin-bottom: 3vh;
                    }
                }

                .slide-sub {
                    color: #fff;
                    @include NotoB;

                    .slide-subtitle {
                        font-size: 2.6rem;
                        line-height: 1.5;
                        letter-spacing: .01em;
                        &:last-of-type {
                            margin-bottom: 20px;
                        }

                        @include sp {
                            font-size: calc(36vw / 7.5);
                        }
                    }

                    .slide-desc {
                        font-size: 1.2rem;
                        line-height: 22px;
                        margin-bottom: 16px;
                    }
                }
            }
        }
        /* ----------------------------------- スライド用スタイル ----------------------------------- */
        .slide-contents {

            .slide-contents__inner {

                .slide-title {
                    overflow: hidden;
                    transform: translateY(100%);
                    position: relative;
                    transition: $transition1;

                    span {
                        display: block;
                        transform: translateY(-100%);
                        transition: $transition1;
                    }
                    
                    .slide.active & {
                        transform: translateY(0);
                        // animation: slideType1 1.5s ease .9s forwards;
                        
                        span {
                            transform: translateY(0);
                            // animation: slideType2 1.5s ease .9s forwards;
                        }
                    }
                }

                .slide-sub {

                    .slide-type {
                        position: relative;
                        
                        span {
                            display: block;
                            transform: translateY($beforeFadePos);
                            opacity: 0;
                            transition: $transition2;
                        }

                        .slide.active & {
                            span {
                                transform: translateY(0);
                                opacity: 1;
                                // animation: fadeUpType1 1.5s ease 1.3s forwards;
                            }
                        }
                    }

                    .slide-subtitle {
                        position: relative;

                        span {
                            display: block;
                            transform: translateY($beforeFadePos);
                            opacity: 0;
                            transition: $transition2;
                        }

                        .slide.active & {
                            span {
                                transform: translateY(0);
                                opacity: 1;
                                // animation: fadeUpType1 1.5s ease 1.3s forwards;
                            }
                        }
                    }

                    .slide-desc {
                        position: relative;

                        span {
                            display: block;
                            transform: translateY($beforeFadePos);
                            opacity: 0;
                            transition: $transition2;
                        }

                        .slide.active & {
                            span {
                                transform: translateY(0);
                                opacity: 1;
                                // animation: fadeUpType1 1.5s ease 1.3s forwards;
                            }
                        }
                    }
                }
                .btn-box {
                    opacity: 0;
                    transition: $transition2;

                    .slide.active & {
                            opacity: 1;
                            // animation: fadeInType1 2s ease 1.3s forwards;
                    }
                }
            }
        }
    }
}

.main-visual {

    &__inner {
        position: relative;

        .mv-progress {
            position: absolute;
            z-index: 100;
            top: 26vh;
            right: 12.5vw;

            display: flex;
            flex-direction: column;
            align-items: center;

            @include tabMid {
                top: 23.5vh;
            }
            @include sp {
                top: 17vh;
                right: 5%;
            }
            &-bar {
                height: 42.683vh;
                width: 1px;
                position: relative;
                background-color: #fff;

                @include sp {
                    height: 46.683vh;
                }

                    &-line {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        height: 0;
                        background-color: $blue;
                        overflow: visible !important;
                    }
            }
            &-place {
                padding: 10px 0;
                width: 12px;

                .progress-inc {
                    margin-bottom: 7px;
                    width: 100%;
                    height: 1px;
                    background-color: #fff;
                    transition: all .2s;

                    &.active {
                        background-color: $blue;

                    }
                }

                .progress-inc:last-of-type {
                    margin-bottom: 0;
                }
            }
            &-num {
                position: relative;
                width: 12px;

                .progress-num {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;

                    @include RobBlack;
                    font-size: 13px;
                    color: $blue;
                    opacity: 0;
                    transition: all .2s;
                    
                    &.active {
                        opacity: 1;
                    }
                }
            }
        }
    }
}

.section-intro {
    padding: 50px 0 50px 0;
    text-align: center;
    width: 95%;
    margin: auto;

    @include tab {
        padding: calc(70vw / 7.5 ) 0 50px 0;
    }

    @include tabMid {
        padding: calc(100vw / 7.5 ) 0 50px 0;
    }

    &__inner {
        width: 100%;

        .intro-title {
            @include NotoB;
            font-size: 7rem;
            line-height: 1.3;
            margin-bottom: 27px;

            @include mp {
                font-size: calc(50vw / 7.5);
            }

            @include tab {
                line-height: 1.4;
                margin-bottom: calc(38vw / 7.5);;
            }

            span {
                display: block;
                @include oswaldBold;
                font-size: 2.2rem;
                color: $gray;
                margin-top: 2.5rem;

                @include tabMid {
                    font-size: calc(24vw /7.5);
                    margin-top: calc(37vw /7.5);
                }
            }
        }

        .intro-desc {
            padding-top: 27px;
            padding-bottom: 45px;

            @include tabMid {
                padding: calc(35vw / 7.5)  0 calc(70vw / 7.5) 0;
                @include spWidth;
                margin: auto;
            }

            span {
                display: block;
                @include NotoM;
                font-size: 1.6rem;
                line-height: 3.5rem;

                @include tabMid {
                    font-size: calc(24vw / 7.5);
                    line-height: 2.18;
                }
            }
        }
    }
}
.section-services {
    padding: 120px 0 0 0;

    &__inner {
        padding: 20px 52px 60px 52px;
        background-image: url(../img/common/bg-gray.png);
        background-repeat: repeat;
        
        @include tab {
            padding: 20px 5% 0px 5%;
            margin: auto;
        }
        .service-container {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            margin-top: -100px;
            
            @include tab {
                flex-wrap: wrap;
                justify-content: space-around;
            }
            .service-content {
                width: 33.333333%;

                @include tab {
                    width: 50%;
                    margin: 0 0 25px 0;
                }

                @include sp {
                    width: 100%;
                }

                &__inner {
                    width: 100%;

                    @include tab {
                        width: 85%;
                        min-width: 300px;
                        margin: auto;
                    }

                    @include tabMid {
                        width: 90%;
                        min-width: 270px;
                    }

                    @include sp {
                        width: 100%;
                        margin: 0;
                    }
    
                }

                .img-box {
                    position: relative;
                    width: 100%;
                    padding-top: 49.3303%;

                    &::before {
                        position: absolute;
                        content: "";
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background-color: rgba(14, 76, 89, 0.2);
                        z-index: 1;
                    }

                    img {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                    }

                    .service-name {
                        position: absolute;
                        top: 47%;
                        left: 10%;
                        color: #fff;
                        @include NotoM;
                        font-size: calc( 22vw / 14.4 );
                        letter-spacing: .1em;
                        text-transform: uppercase;
                        z-index: 2;

                        @include sp {
                            font-size: calc( 28vw / 7.5 );
                        }

                        span {
                            display: block;
                            @include MontB;
                            font-size: 1.2rem;
                            color: $blue;
                            margin-top: 15px;
                            letter-spacing: .03em;
                        }
                    }
                }

                .service-appeal {
                    width: 90%;
                    margin: -10% auto 0 auto;
                    position: relative;
                    background-color: #fff;
                    z-index: 3;

                    &__inner {
                        padding: 34px 24px;

                        @include mp {
                            font-size: calc( 15vw / 14.4 );
                            padding: 34px 20px;
                        }

                        @include sp {
                            font-size: calc( 15vw / 14.4 );
                            padding: calc( 48vw / 7.5 ) calc( 40vw / 7.5 );
                        }
                        .service-ov {
                            color: $deep_gray;
                            @include NotoM;
                            font-size: calc( 16vw / 14.4 );
                            letter-spacing: .03em;
                            margin-bottom: calc( 34vw / 14.4 );
                            white-space: nowrap;

                            @include mp {
                                font-size: calc( 15vw / 14.4 );
                            }

                            // @include tab {
                            //     font-size: calc( 20vw / 7.5 );
                            //     margin-bottom: calc( 44vw / 7.5 );
                            // }

                            @include sp {
                                font-size: calc( 24vw / 7.5 );
                            }
                        }

                        .service-txt {
                            padding-top: 34.7096%;
                            color: $deep_gray;
                            @include NotoM;
                            font-size: calc( 14vw / 14.4 );
                            line-height: 1.86;
                            letter-spacing: 0;
                            position: relative;
                            margin-bottom: 20px;

                            @include sp {
                                padding-top: 26.7096%;
                                font-size: calc( 20vw / 7.5 );
                            }

                            &__inner {
                                position: absolute;
                                top: 0;
                                left: 0;
                                height: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}

.section-case {

}
.partners {
   @include mainWidth;
   margin: auto;
   padding-top: 35px;
   padding-bottom: 80px;

   @include sp {
       @include spWidth;
   }
   &__inner {
       text-align: center;

       .partners-img {
            width: 100%;
            // max-width: 753px;
            // margin: auto;

            @include sp {
                width: 100%;
            }
            img {
                width: 100%;
            }
       }
   }
}
.career-img {
    height: calc(560vw /14.4);
    overflow: hidden;
    position: relative;

    @include sp {
        height: calc(443vw /7.5);
    }
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(14, 76, 89, 0.2);
        z-index: 10;
    }
    // overflow-x:hidden;
    // overflow-y:auto;
    // perspective:300px;
    // margin-bottom: 1000px;
    // .parallax-window {
    //     height: 100%;
    //     // transform:translateZ(200px) scale(.7);
    //     // z-index:1;
    // }
    .para-content {
        position: absolute;
        top: -40%;
        left: 0;
        min-width: 100%;
        height: 175%;
        background-image: url(../img/top/para-top1.jpg);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
    }
}
.news {
    background-color: $bg_gray;

    &__inner {
        padding: 85px 0;
        @include mainWidth;
        margin: auto;

        @include mp {
            padding: 45px 0;
        }
        @include sp {
            padding: calc(70vw / 7.5) 0;
            @include spWidth;
        }
        .news-top {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            margin-bottom: 60px;

            @include mp {
                margin-bottom: 40px;
            }
            @include sp {
                margin-bottom: calc(45vw /7.5);
            }
            .news-head {
                @include NotoB;
                color: $deep_gray;
                letter-spacing: .1em;
                font-size: 1.6rem;
                line-height: 2;
    
                span {
                    display: block;
                    @include MontB;
                    font-size: 1.1rem;
                }
            }

            .news-more {
                line-height: 2.5;
                .news-list-link {
                @include MontB;
                color: $blue;
                font-size: 1.2rem;
                letter-spacing: .03em;
                display: flex;
                align-items: center;
                @include hoverOpa;

                    span {
                        display: block;
                        position: relative;
                        // vertical-align: middle;
                        margin-left: 8px;
                        width: 13px;

                        &::before,
                        &::after {
                            position: absolute;
                            content: "";
                            top: 0;
                            bottom: 0;
                            right: 0;
                            // transform: translateX(100%);
                            margin: auto;
                            vertical-align: middle;
                        }

                        &::before {
                            width: 13px;
                            height: 1px;
                            background-color: $blue;
                        }

                        &::after {
                            // right: -13px;
                            width: 6px;
                            height: 6px;
                            border-top: 1px solid $blue;
                            border-right: 1px solid $blue;
                            -webkit-transform: rotate(45deg);
                            transform: rotate(45deg);
                        }
                    }
                }
            }
        }

        .news-list {

            .news-row {
                display: flex;
                @include NotoR;
                font-size: 1.3rem;
                letter-spacing: .08em;
                padding: 10px 0;

                @include mp {
                    flex-direction: column;
                    padding: calc(10vw / 7.5) 0;
                }
                @include sp {
                    padding: calc(25vw / 7.5) 0;
                }
                .news-info {
                    display: flex;
                    color: $deep_gray;
                    width: 24%;

                    @include mp {
                        width: 100%;
                        margin-bottom: calc(10vw / 7.5);
                    }
                    @include sp {
                        width: 100%;
                        margin-bottom: calc(25vw / 7.5);
                    }

                    .news-date {
                        width: 45%;

                        @include mp {
                            width: 80px;
                            margin-right: 10px;
                        }
                    }
                    .news-type {
                        width: 55%;
                    }
                }

                .news-title {
                    color: $blue;
                    width: 76%;
                    line-height: 1.4;
                    @include hoverOpa;

                    @include mp {
                        width: 100%;
                    }
                    
                    .news-link {
                        color: $blue;
                    }
                }
            }
        }
    }
}

.section-column {

    .column-btn-container {
        padding: 25px 0;
        @include mainWidth;
        margin: auto;
        display: flex;
        justify-content: flex-end;

        .column-more {
            @include MontB;
            color: $blue;
            font-size: 1.2rem;
            letter-spacing: .03em;
            display: flex;
            align-items: center;
            @include hoverOpa;

            span {
                display: block;
                position: relative;
                // vertical-align: middle;
                margin-left: 8px;
                width: 13px;

                &::before,
                &::after {
                    position: absolute;
                    content: "";
                    top: 0;
                    bottom: 0;
                    right: 0;
                    // transform: translateX(100%);
                    margin: auto;
                    vertical-align: middle;
                }

                &::before {
                    width: 13px;
                    height: 1px;
                    background-color: $blue;
                }

                &::after {
                    // right: -13px;
                    width: 6px;
                    height: 6px;
                    border-top: 1px solid $blue;
                    border-right: 1px solid $blue;
                    -webkit-transform: rotate(45deg);
                    transform: rotate(45deg);
                }
            }
        }
    }
}

.section-team {
    background-color: $bg_gray;
    overflow: hidden;

    &-main {
        width: 90%;
        max-width: 1080px;
        margin: auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        @include sp {
            // スマホスワイパー用
            display: block;
            width: 100%;
        }

        .swiper-wrapper {
            // スマホスワイパー用
            display: flex;
            justify-content: flex-start;
            
        }

        .member-box {
            // スマホスワイパー用
            width: 100%;
            padding: 0 5%;
            display: flex;
            justify-content: space-between;

        }

        .team-swiper-pagination {
            // スマホスワイパー用
            text-align: center;

            .swiper-pagination-bullet {
                width: 10px;
                height: 10px;
            }
            .swiper-pagination-bullet-active {
                background: $blue;
            }
        }

        .member-card {
            width: 23.1481%;
            margin-bottom: 30px;

            @include mp {
                width: 47%;
                margin-bottom: 0;
                
                &:nth-of-type(-n+2) {
                    margin-bottom: 30px;
                }
            }

            .member-img {
                width: 100%;
                padding-top: 72%;
                position: relative;

                img {
                    position: absolute;
                    top: 0;
                    left: 0;

                    width: 100%;
                    object-fit: cover;
                }
            }

            .member-info {
                padding: 18px;
                background-color: #fff;
                width: 82.35%;
                margin: auto;

                position: relative;
                margin-top: -7%;

                @include sp {
                    padding: calc(24vw / 7.5);
                }

                .member-branch,
                .member-sort {
                    @include NotoM;
                    font-size: 1.1rem;
                    letter-spacing: .02em;
                    line-height: 1.6;
                    color: $blue;

                    @include mp {
                        font-size: calc(13vw / 7.5);
                        line-height: 1.3;
                    }

                    @include sp {
                        font-size: calc(18vw / 7.5);
                    }
                }
                .member-branch {
                    @include mp {
                        margin-bottom: 5px;
                    }
                }
                .member-sort {
                    @include mp {
                        margin-bottom: 7px;
                    }
                }

                // .member-branch {
                //     text-align: justify;
                // }

                .supple {
                    @include NotoM;
                    font-size: 1.1rem;
                    letter-spacing: .02em;
                    line-height: 1.6;
                    color: $deep_gray;

                    @include mp {
                        font-size: calc(12vw / 7.5);
                    }

                    @include sp {
                        font-size: calc(15vw / 7.5);
                    }
                }

                .member-name {
                    margin-top: 18px;
                    @include NotoM;
                    font-size: 1.8rem;
                    color: #000;
                    letter-spacing: .1em;
                    line-height: 1.22;

                    @include mp {
                        font-size: calc(14vw / 7.5);
                    }

                    @include sp {
                        font-size: calc(24vw / 7.5);
                        margin-top: calc(25vw / 7.5);
                    }

                    span {
                        margin-top: 6px;
                        display: block;
                        @include MontB;
                        font-size: 1rem;
                        letter-spacing: .03em;
                        color: $deep_gray;

                        @include sp {
                            font-size: calc(15vw / 7.5);
                        }

                    }

                }
            }
        }
    }

    .btn-area {
        padding: 40px 0 50px 0;
    }
}

.section-recruit {
    overflow: hidden;

    @include sp {
        padding-bottom: calc(50vw / 7.5);
    }

    &-main {
        // @include mainWidth;
        margin: auto;
        margin-bottom: 120px;
        height: calc(440vw / 14.4);
        background-image: url(../img/common/recruit-main-t.jpg);
        background-repeat: no-repeat;
        background-size: 1440px;
        background-position: 50%;
        padding-top: 10%;

        @include mp {
            padding-top: 8%;
        }
        @include tab {
            height: 300px;
            background-size: 1000px;
            @include spWidth;
            margin-bottom: calc(120vw / 7.5);
        }

        @include tabMid {
            height: calc(415vw / 7.5);
            background-image: url(../img/top/sp-recruit-main.jpg);
            background-size: cover;
        }

        @include sp {
            margin-bottom: calc(50vw / 7.5);
        }
        
        .recruit-content {
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            @include tabMid {
                margin-top: 7vw;
                align-items: initial;
            }
            &__inner {
                margin: auto;
                transform: translateX(270px);

                @include mp {
                    transform: translateX(200px);
                }
                @include tab {
                    transform: translateX(170px);
                }
                @include tabMid {
                    transform: initial;
                    margin: 0;
                }

                .recruit-msg {
                    @include NotoM;
                    font-size: 2.4rem;
                    color: $navy;
                    letter-spacing: .1em;
                    line-height: 1.6;
                    margin-bottom: calc(60vw / 14.4);

                    @include tab {
                        font-size: 2rem;
                    }
                    @include tabMid {
                        font-size: calc(26vw / 7.5);
                        transform: translateX(40vw);
                        line-height: 1.4;
                    }
                    
                    span {
                        display: block;
                        @include MontB;
                        font-size: 1rem;
                        margin-top: calc(24vw / 14.4);
                        line-height: 1.5;

                        @include tabMid {
                            margin-top: calc(15vw / 7.5);
                        }
        
                    }
                }
                .btn-box {

                    @include tabMid {
                        margin: calc(50vw / 7.5) auto 0 auto;
                    }
                }
            }
        }
    }
}
.section-place {

    &__inner {
        @include mainWidth;
        margin: auto;

        @include tab {
            @include spWidth;
        }
        .g-map {
            height: calc(300vw / 14.4);
            margin-bottom: 40px;

            @include tab {
                height: calc(400vw / 14.4);
            }

            @include tabMid {
                height: calc(367vw / 7.5);
                margin-bottom: calc(33vw / 7.5);

            }
        }

        .office-info {
            display: flex;
            margin-bottom: 70px;

            @include tabMid {
                flex-direction: column;
                margin-bottom: calc(55vw / 7.5);
            }

            .office-branch {
                @include NotoM;
                font-size: 1.4rem;
                color: $deep_gray;
                letter-spacing: .04em;
                line-height: 1.6;
                margin-right: 45px;

                @include tabMid {
                    font-size: calc(30vw / 7.5);
                    margin-bottom: calc(25vw / 7.5);
                }
                span {
                    display: block;
                    @include MontB;
                    font-size: 1rem;
                    letter-spacing: .03em;
                    color: $blue;

                    @include tabMid {
                        font-size: calc(20vw / 7.5);
                    }
                }
            }

            .office-detail {
                @include NotoR;
                font-size: 1.4rem;
                color: $deep_gray;
                letter-spacing: .03em;
                line-height: 1.6;
            }
        }
    }
}

.btn-box {

    &-inner {

        .btn-more {
    
            &::after {
                background-image: url(../img/common/arr.png);
            }
        }
    }

}