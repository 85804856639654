@import "variables";

html {
    font-size: 62.5%;
}

@mixin hoverOpa {
    cursor: pointer;

    &:hover {
        opacity: .5;

        @include tab {
            opacity: 1;
        }
    }
}

body {

    &.no-scroll {
        overflow: hidden;
    }
}

.top-header {
    width: 100%;
    position: relative;
    z-index: 200;

    &.normal {
        position: absolute;
        top: 0;
        left: 0;

        @include tab {
            position: fixed;
            top: 0;
            left: 0;

        }
    }

    &.page {
        position: fixed;
        top: 0;
        left: 0;

        @include tab {
            display: none;
        }

        &.remain-tab {
            display: block;
        }
    }

    &.fixed {
        position: fixed;
        top: 0;
        left: 0;
        background-color: rgba(255, 255, 255 , 0.7);
    }

    &__inner {
        @include mainWidth;
        max-width: 1080px;
        margin: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 40px 0;

        @include mp {
            width: 90%;
        }

        @include tabMid {
            padding: calc(40vw / 7.5) 0;
        }

        .header-logo {
            height: 37px;

            @include hoverOpa;

            @include sp {
                height: 28px;
            }

            img {
                display: block;
                height: 100%;
                width: auto;
            }
        }

        .top-nav {

            @include tab {
                display: none;
            }

            &__inner {
                display: flex;
                align-items: center;
                height: 100%;

                .nav-item {
                    padding: 0 10px;

                    @include hoverOpa;

                    .nav-link {
                        @include RobBlack;
                        font-size: 12px;
                        letter-spacing: .12em;
                        color: $navy;
                        cursor: pointer;
                    }
                }
                .btn-contact {
                    width: 140px;
                    height: 45px;
                    border-radius: 22.5px;
                    background-color: $navy;
                    margin-left: 18px;

                    &.none {
                        display: none;
                    }

                    @include hoverOpa;

                    .contact-link {
                        width: 100%;
                        height: 100%;
                        @include RobBlack;
                        color: #fff;
                        font-size: 12px;
                        letter-spacing: .06em;

                        display: flex;
                        justify-content: center;
                        align-items: center;

                    }
                }
            }
        }

        .menu-btn {
            display: none;

            @include hoverOpa;

            @include tab {
                display: flex;
            }

            
        }
    }
}

main.page-main {
    margin-top: 125px;
    position: relative;
    z-index: 100;
    margin-bottom: 316px;


    @include tabMid {
        margin-top: calc( 40px + calc(94vw / 7.5));
    }

    @include sp {
        margin-bottom: calc(598vw / 7.5);
    }
    .page-body {
        background-color: #fff;
        overflow: hidden;

        &.no-overflow {
            overflow: initial;
        }
    }
}

.page-head {
    padding-top: 30px;

    @include tabMid {
        padding-top: calc(35vw / 7.5);
    }

    @include sp {
        padding-top: 0;
    }

    &__inner {
        @include mainWidth;
        margin: auto;

        @include mp {
            @include spWidth;
        }

        .page-title {
            @include NotoB;
            color: $ttlNavy;
            font-size: 2.4rem;
            letter-spacing: .14em;
            line-height: 1.6;

            @include tabMid {
                font-size: calc(38vw / 7.5);
            }

            span {
                display: block;
                @include MontB;
                font-size: 1rem;
                letter-spacing: .08em;
                
            }
        }
    }
}

.main-nav-container {
    position: relative;
    height: calc(80px + 50 * ((100vw - 320px)/ 1440));

    @include sp {
        height: calc(83px + 50 * ((100vw - 320px)/ 1440));
    }

    &.close-tab {

        @include tab {
            display: none;
        }
    }

    .main-nav {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 1000;
        height: calc(80px + 50 * ((100vw - 320px)/ 1440));
        display: flex;
        align-items: center;
        background-color: rgba(255, 255, 255 , 0.7);


        @include sp {
            height: calc(83px + 50 * ((100vw - 320px)/ 1440));
        }
        

        &.nav-fixed {
            position: fixed;
            top: 0;
            left: 0;
        }

        &__inner {
            @include mainWidth;
            max-width: 1080px;
            margin: auto;
            // padding: 45px 0;
            display: flex;
            justify-content: space-between;
            align-items: center;

            @include tab {
                @include spWidth;
            }
    
            // @include tabMid {
            //     padding: calc(40vw / 7.5) 0;
            // }
            &.recruit {
                position: relative;

                .btn-entry {
                    position: absolute;
                    top: 160%;
                    right: 0;
                    transform: translateX(28.5%);
                    display: block;
                    width: 120px;
                    height: 120px;
                    background-color: $navy;
                    @include MontB;
                    font-size: 2.2rem;
                    // letter-spacing: .12em;
                    color: #fff;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    @include tabMid {
                        width: calc(120vw / 7.5);
                        min-width: 80px;
                        height: calc(120vw / 7.5);
                        min-height: 80px;
                        transform: translateX( calc( calc( 120vw / 7.5 ) / 4.3) );
                    }

                    @include sp {
                        font-size: 1.7rem;
                    }
                    
                    &:hover {
                        background-color: #65c2d5;
                    }

                }
            }
    
            .main-nav-logo {
                height: 37px;

                @include hoverOpa;

                @include sp {
                    height: 28px;
                }
    
                img {
                    height: 100%;
                    width: auto;
    
                }
            }
        }
    }
}


.top-nav {

    &__inner {

        position: relative;

        .btn-entry {
            // position: absolute;
            // top: 160%;
            right: 0;
            transform: translateX(28.5%);
            display: block;
            width: 120px;
            height: 120px;
            background-color: $navy;
            @include MontB;
            font-size: 2.2rem;
            color: #fff;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
        
            @include tabMid {
                width: calc(120vw / 7.5);
                min-width: 80px;
                height: calc(120vw / 7.5);
                min-height: 80px;
                transform: translateX( calc( calc( 120vw / 7.5 ) / 4.3) );
            }
        
            @include sp {
                font-size: 1.7rem;
            }
            
            &:hover {
                background-color: #65c2d5;
            }
        }
    }
}

@keyframes fadeUpMenu {
    from {
        opacity: 0;
        // transform: translateY(2%);
    }
    to {
        opacity: 1;
        // transform: translateY(0);
    }
}

@keyframes fadeOutMenu {
    from {
        opacity: 1;
        // transform: translateY(0);
    }
    to {
        opacity: 0;
        // transform: translateY(2%);
    }
}

@keyframes showMainMenu {
    0% {
        height: 0;
    }
    99% {
        height: calc(1559vw / 6);
    }
    100% {
        height: auto;
    }
}

@keyframes hideMainMenu {
    0% {
        height: auto;
    }
    1% {
        height: calc(1559vw / 6);
    }
    100% {
        height: 0;
    }
}

@mixin fadeUpMenu_anime {
    animation: fadeUpMenu 1.5s ease-in-out forwards;
}
@mixin fadeOutMenu_anime {
    animation: fadeOutMenu .4s ease-in-out forwards;
}
@mixin showMainMenu_anime {
    animation: showMainMenu .7s ease-in-out forwards;
}
@mixin hideMainMenu_anime {
    animation: hideMainMenu .7s ease-in-out forwards;
}

.m-menu {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 5000;

    display: none;

    @include sp {
        overflow-y: scroll;
        // -webkit-overflow-scrolling: touch;
    }
    
    &__inner {

        .m-menu-top {
            height: calc(80px + 50 * ((100vw - 320px)/ 1440));

            @include sp {
                height: calc(83px + 50 * ((100vw - 320px)/ 1440));
            }

            .m-menu-header {
                width: 100%;
                background-color: #024272;
                height: 0;
                display: flex;
                justify-content: center;
                align-items: center;
    
                &__inner {
                    @include mainWidth;
                    max-width: 1080px;
                    height: 0;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    @include tabMid {
                        @include spWidth;
                    }
    
                    .menu-header-logo {
                        height: 37px;
                        opacity: 0;

                        @include sp {
                            height: 28px;
                            margin-left: 5vw;
                        }
                        svg {
                            height: 100%;

                            @include sp {
                                transform: translateX(-13px);
                            }

                            polygon,
                            path,
                            rect {
                                fill: #fff;
                            }
                        }
                    }
                    
                    .close-btn {
                        background-color: #0f5b92;
                        width: 54px;
                        height: 54px;
                        border-radius: 50%;
                        position: relative;
                        cursor: pointer;
                        opacity: 0;

                        @include sp {
                            width: 45px;
                            height: 45px;
                        }

                        span {
    
                            &::before,
                            &::after {
                                display: block;
                                content: "";
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                width: 17px;
                                height: 2px;
                                border-radius: 1px;
                                margin: -2.5% 0 0 -15%;
                                background: #FFF;

                                @include sp {
                                    margin: -0% 0 0 -18.3%;
                                }
                            }
    
    
                            &::before {
                                transform: rotate(-45deg);
                            }
                            &::after {
                                transform: rotate(45deg);
                            }
                        }
                    }
                }
            }
        }

        .m-menu-main {
            display: flex;

            @include sp {
                flex-direction: column;
                height: 0;
                transition: all .7s;

                &.show {
                    @include showMainMenu_anime;
                }
                &.hide {
                    @include hideMainMenu_anime;
                }
            }

            // @include spVer {
            //     &.show {
            //         height: calc(1524vw / 6);
            //     }
            // }

            .menu-ml {
                width: 57.6388vw;
                height: calc(100vh - calc(80px + 50 * ((100vw - 320px)/ 1440)));
                overflow-y: scroll;
                position: relative;

                @include sp {
                    width: 100vw;
                    height: auto;
                    overflow-y: auto;
                }

                &__inner {
                    width: 0;
                    min-height: 100%;
                    background-color: #0f5b92;

                    @include sp {
                        width: 100%;
                        min-height: auto;
                    }

                    .menu-ml-content {
                        padding-top: calc(50px + 30 * ((100vw - 320px)/ 1440));
                        padding-bottom: calc(50px + 30 * ((100vw - 320px)/ 1440));
                        padding-left: 12.6388vw;
                        padding-right: 4.5833vw;
                        opacity: 0;

                        @include tab {
                            padding-left: 4.5833vw;
                        }

                        @include sp {
                            padding-top: calc(70vw / 7.5);
                            padding-bottom: calc(70vw / 7.5);
                        }
                        &.show {
                            @include fadeUpMenu_anime;
                        }
                        &.out {
                            @include fadeOutMenu_anime;
                        }
                        .menu-ml-menu {

                            .ml-menu-title {
                                @include MontB;
                                font-size: 1.4rem;
                                letter-spacing: .1em;
                                color: #fff;
                                margin-bottom: 50px;

                                @include sp {
                                    margin-bottom: calc(70vw / 7.5);
                                    font-size: calc(20vw / 7.5);
                                }
                            }

                            .menu-lists {
                                display: flex;

                                .menu-list {
                                    width: 50%;

                                    .menu-item {
                                        margin-bottom: 40px;
                                        
                                        &:nth-last-child(1) {
                                            margin-bottom: 0px;
                                        }
                                        @include sp {
                                            margin-bottom: calc(50vw / 7.5);
                                        }
                                        .menu-link {
                                            @include MontB;
                                            font-size: 2.2rem;
                                            letter-spacing: .05em;
                                            color: #fff;

                                            @include mp {
                                                font-size: 2rem;
                                            }

                                            @include tabMid {
                                                font-size: calc(18vw / 7.5);
                                            }

                                            @include sp {
                                                font-size: calc(34vw / 7.5);
                                            }

                                            span {
                                                @include NotoB;
                                                font-size: 1.2rem;
                                                color: $gray;
                                                margin-left: 8px;

                                                @include tabMid {
                                                    font-size: 1rem;
                                                }

                                                @include sp {
                                                    font-size: calc(20vw / 7.5);
                                                    margin-left: calc(8vw / 7.5);
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .menu-ml-rec {
                            margin-top: 60px;
                            width: 100%;
                            padding-top: 30.7692%;
                            border-radius: 15px;
                            position: relative;
                            
                            @include sp {
                                margin-top: calc(83vw / 7.5);
                            }

                            .bg-rec {
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                border-radius: 15px;
                            }

                            &-content {
                                position: absolute;
                                top: 50%;
                                transform: translateY(-50%);
                                right: 10%;

                                .menu-ml-rec-txt {
                                    @include MontB;
                                    font-size: 3rem;
                                    letter-spacing: .1em;
                                    color: $navy;

                                    @include sp {
                                        font-size: calc(35vw / 7.5);
                                    }
                                    span {
                                        display: block;
                                        @include NotoM;
                                        font-size: 1.4rem;
                                        margin-bottom: 10px;

                                        @include sp {
                                            font-size: calc(20vw / 7.5);
                                        }
                                    }
                                }
                            }
                        }
                        .menu-ml-fb {
                            color: #fff;
                            @include MontB;
                            font-size: 1rem;
                            letter-spacing: .1em;
                            display: flex;
                            align-items: center;
                            margin-top: 40px;
                            line-height: 2rem;

                            @include sp {
                                margin-top: calc(55vw / 7.5);
                            }
                            .fab.fa-facebook {
                                font-size: 1.5rem;
                                vertical-align: middle;
                                margin-right: 15px;

                                &::before {
                                    border-radius: 50%;
                                }
                            }
                        }
                    }
                }
            }

            .menu-srv {
                width: calc(100vw - 57.6388vw);
                height: calc(100vh - calc(80px + 50 * ((100vw - 320px)/ 1440)));
                overflow-y: scroll;
                position: relative;

                @include sp {
                    width: 100vw;
                    height: auto;
                    overflow-y: auto;
                }

                &__inner {
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 0;
                    min-height: 100%;
                    background-color: #f7f7f7;
                    
                    @include sp {
                        position: static;
                        width: 100%;
                    }
                    .menu-srv-content {
                        padding-top: calc(50px + 30 * ((100vw - 320px)/ 1440));
                        padding-bottom: calc(50px + 30 * ((100vw - 320px)/ 1440));
                        padding-right: calc(183vw / 14.4);
                        padding-left: calc(72vw / 14.4);
                        opacity: 0;
                        // transform: translateY(2%);

                        @include tab {
                            padding-right: calc(72vw / 14.4);
                        }

                        @include sp {
                            padding-top: calc(70vw / 7.5);
                            padding-bottom: calc(100vw / 7.5);
                        }
                        &.show {
                            @include fadeUpMenu_anime;
                        }
                        &.out {
                            @include fadeOutMenu_anime;
                        }
                        
                        .menu-srv-title {
                            @include MontB;
                            font-size: 1.4rem;
                            letter-spacing: .1em;
                            color: #0f5b92;
                            margin-bottom: 50px;

                            @include sp {
                                font-size: calc(20vw / 7.5);
                                margin-bottom: calc(70vw / 7.5);
                            }
                        }

                        .menu-srv-box {
                            
                            .srv-box {
                                display: block;
                                width: 100%;
                                padding-top: 33.8028%;
                                background-color: #fff;
                                border-radius: 15px;
                                box-shadow: 0px -1px 4px 4px #efeeee;
                                margin-bottom: 19px;
                                position: relative;
                                cursor: pointer;
                                transition: all .3s;

                                @include sp {
                                    margin-bottom: calc(19vw / 3.7);
                                }

                                // &:hover {
                                //     transform: scale(1.05);
                                // }
                                &::after {
                                    transition: all .3s;
                                }

                                &::before,
                                &::after {
                                    border-radius: 15px;
                                    background: rgb(21, 120, 191);
                                    content: '';
                                    position: absolute;
                                    z-index: 0;
                                }
                                &::before {
                                    height: 100%;
                                    left: 0;
                                    top: 0;
                                    width: 100%;
                                }
                                &::after {
                                    background: #fff;
                                    height: 100%;
                                    left: 0;
                                    top: 0;
                                    width: 100%;
                                }
                                &:hover:after {
                                    height: 0;
                                    left: 50%;
                                    top: 50%;
                                    width: 0;
                                }
                                .srv-logo {
                                    position: absolute;
                                    top: 50%;
                                    left: 50%;
                                    transform: translate(-50%, -50%);
                                    z-index: 1;
                                    width: 63%;

                                    // @include tab {
                                    //     width: calc(190vw / 10.24);
                                    // }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

// チーム紹介（一覧ページ以外）
.section-team {
    background-color: $bg_gray;
    overflow: hidden;

    &.page-about {
        background-color: #fff;
    }

    &-main {
        width: 90%;
        max-width: 1080px;
        margin: auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        @include sp {
            // スマホスワイパー用
            display: block;
            width: 100%;
        }

        .swiper-wrapper {
            // スマホスワイパー用
            display: flex;
            justify-content: flex-start;
            
        }

        .member-box {
            // スマホスワイパー用
            width: 100%;
            padding: 0 5%;
            display: flex;
            justify-content: space-between;

        }

        .team-swiper-pagination {
            // スマホスワイパー用
            text-align: center;

            .swiper-pagination-bullet {
                width: 10px;
                height: 10px;
            }
            .swiper-pagination-bullet-active {
                background: $blue;
            }
        }

        .member-card {
            width: 23.1481%;
            margin-bottom: 30px;

            @include mp {
                width: 47%;
                margin-bottom: 0;
                
                &:nth-of-type(-n+2) {
                    margin-bottom: 30px;
                }
            }

            .member-img {
                width: 100%;
                padding-top: 72%;
                position: relative;

                img {
                    position: absolute;
                    top: 0;
                    left: 0;

                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .member-info {
                padding: 18px;
                background-color: #fff;
                width: 82.35%;
                margin: auto;

                position: relative;
                margin-top: -7%;

                @include sp {
                    padding: calc(24vw / 7.5);
                }

                .member-branch,
                .member-sort {
                    @include NotoM;
                    font-size: 1.1rem;
                    letter-spacing: .02em;
                    line-height: 1.6;
                    color: $blue;

                    @include mp {
                        font-size: calc(13vw / 7.5);
                        line-height: 1.3;
                    }

                    @include sp {
                        font-size: calc(18vw / 7.5);
                    }
                }
                .member-branch {
                    @include mp {
                        margin-bottom: 5px;
                    }
                }
                .member-sort {
                    @include mp {
                        margin-bottom: 7px;
                    }
                }

                // .member-branch {
                //     text-align: justify;
                // }

                .supple {
                    @include NotoM;
                    font-size: 1.1rem;
                    letter-spacing: .02em;
                    line-height: 1.6;
                    color: $deep_gray;

                    @include mp {
                        font-size: calc(12vw / 7.5);
                    }

                    @include sp {
                        font-size: calc(15vw / 7.5);
                    }
                }

                .member-name {
                    margin-top: 18px;
                    @include NotoM;
                    font-size: 1.8rem;
                    color: #000;
                    letter-spacing: .1em;
                    line-height: 1.22;

                    @include mp {
                        font-size: calc(14vw / 7.5);
                    }

                    @include sp {
                        font-size: calc(24vw / 7.5);
                        margin-top: calc(25vw / 7.5);
                    }

                    span {
                        margin-top: 6px;
                        display: block;
                        @include MontB;
                        font-size: 1rem;
                        letter-spacing: .03em;
                        color: $deep_gray;

                        @include sp {
                            font-size: calc(15vw / 7.5);
                        }

                    }

                }
            }
        }
    }

    .btn-area {
        padding: 40px 0 50px 0;
    }
}

// ------------事例紹介------------
.related-case {
    padding-bottom: 50px;

    @include tabMid {
        padding-bottom: calc(50vw/ 7.5);
    }
}
.case-blog-list {

    &__inner {
        @include mainWidth;
        margin: auto;

        .case-blog-container {
            width: 100%;
            margin-bottom: 50px;

            padding-top: 50px;

            &:nth-of-type(1) {
                padding-top: 0px;
            }

            &:nth-last-child(1) {

                @include sp {
                    margin-bottom: 15px;
                }
            }

            .case-thum-box {
                display: block;
                width: 100%;
                padding-top: 39%;
                position: relative;

                @include sp {
                    padding-top: 43%;
                }

                .case-thum {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                .case-logo {
                    position: absolute;
                    bottom: 15px;
                    left: 15px;
                    width: 127px;
                    height: 127px;
                    object-fit: contain;
                    background-color: #fff;

                    @include tabMid {
                        width: 90px;
                        height: 90px;
                    }

                    @include sp {
                        width: 70px;
                        height: 70px;
                    }

                }
            }
        }

        .case-info-container {
            margin-top: 20px;

            .case-info-inner {

                .case-info-top {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 25px;

                    @include sp {
                        margin-bottom: 13px;
                    }

                    .case-info-var {

                        @include tabMid {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            width: 100%;
                        }

                        @include sp {
                            display: block;
                        }

                        .case-cat-types {
                            display: flex;
                            justify-content: flex-start;
                            margin-bottom: 12px;

                            @include tabMid {
                                margin-bottom: 0;
                            }

                            @include sp {
                                margin-bottom: 13px;
                            }

                            .v_input {
                                display: none;
                                padding: 0;
                            }

                            .case-cat-parent,
                            .case-cat-child {
                                display: block;

                                @include NotoB;
                                font-size: 1.2rem;
                                letter-spacing: .06em;
                                color: $blue;
                                padding: 5px 15px;
                                border-radius: 12px;
                                border: 1px solid $blue;
                                margin-right: 7px;
                                @include hoverOpa;
                            }
                        }

                        .case-company {
                            @include NotoB;
                            font-size: 1.4rem;
                            letter-spacing: .06em;
                            color: $blue;

                        }
                    }

                    .btn-area {
                        @include tabMid {
                            display: none;
                        }
                    }
                    .btn-more {
                        right: 0;
                        left: auto;
                        
                    }
                }
            }
        }
        &.page-service {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            @include tabMid {
                flex-direction: column;
            }

            .case-blog-container {
                width: 48%;
                padding-top: 0;
                margin-bottom: 20px;

                @include tabMid {
                    width: 100%;
                }
            }
        }

    }

}

.pagination-container {

    .pagination-inner {
        @include mainWidth;
        margin: auto;
        display: flex;
        justify-content: center;
        padding: 70px 0;

        @include sp {
            padding: 30px 0;
        }

        .pager {
            display: block;
            width: 56px;
            height: 56px;
            display: flex;
            justify-content: center;
            align-items: center;
            @include MontB;
            font-size: 1.3rem;
            color: #fff;
            letter-spacing: .03em;
            background-color: $blue;

            margin-right: 3px;

            &.current {
                background-color: $navy;
            }

            &:hover {
                background-color: $navy;
            }

            &.pager-first {
                margin-left: 15px;
            }
            &.pager-last {
                margin-right: 15px;
            }

            @include sp {
                width: 45px;
                height: 45px;
            }

        }

        .prev,
        .next {
            display: block;
            width: 35px;
            height: 56px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $bg_gray;

            @include sp {
                width: 25px;
                height: 45px;
            }

            &:hover {
                background-color: #999999;
            }

        }

        .prev,
        .next {
            display: flex;
            justify-content: center;
            align-items: center;

            &::before {
                content: "";
                width: 6px;
                height: 10px;
                background-repeat: no-repeat;
                background-size: contain;
            }
        }
        .prev {
            &::before {
                background-image: url(../../../assets/img/common/pagi-prev.png);
            }
        }
        .next {
            &::before {
                background-image: url(../../../assets/img/common/pagi-next.png);
            }
        }
        
        .first,
        .last {
            display: block;
            width: 35px;
            height: 56px;

            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $bg_gray;

            @include sp {
                width: 25px;
                height: 45px;
            }
            
            &:hover {
                background-color: $deep_gray;
            }

            &::before {
                content: "";
                width: 13px;
                height: 11px;
                background-repeat: no-repeat;
                background-size: contain;
            }
        }

        .first {
            margin-right: 3px;

            &::before {
                background-image: url(../../../assets/img/common/pagi-first.png);
            }
        }
        .last {
            margin-left: 3px;
            &::before {
                background-image: url(../../../assets/img/common/pagi-last.png);
            }
        }

    }
}

// SNS
.sns-icons {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 30px;

    // &.pc {

    //     @include sp {
    //         display: none;
    //     }
    // }

    // &.sp {
    //     display: none;
    // }

    @include sp {
        margin-top: calc( 35vw / 7.5);
        margin-bottom: 0;
    }

    .sns-icon {
        display: block;
        width: 30px;
        margin-right: 15px;
        @include hoverOpa;

        @include sp {
            width: calc(45vw / 7.5)
        }

        &:nth-last-of-type(1) {
            margin-right: 0;
        }

        img {
            width: 100%;
        }
    }
}



.menu-btn {
    width: 54px;
    height: 54px;
    border-radius: 50%;
    background-color: $navy;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    @include hoverOpa;

    @include sp {
        width: 45px;
        height: 45px;
    }
    &-lines {
        height: 13px;

        .line {
            width: 15px;
            height: 2px;
            background-color: #fff;

        }
        .line:nth-child(-n+2) {
            margin-bottom: 3px;

        }
    }
}

.btn-area {

    &.padding {
        padding: 45px 0;

        @include tabMid {
            padding: calc(45vw / 7.5) 0;
        }
    }
    .btn-box {
        margin: auto;
    } 
}
.btn-box {
    max-width: 270px;
    height: 50px;

    @include sp {
        // width: calc(430vw / 7.5);
        // height: calc(80vw / 7.5);
        // max-width: initial;
    }

    &.long {
        max-width: 300px;
    }

    &-inner {
        display: block;
        width: 100%;
        height: 100%;
        position: relative;

        .btn-more {
            display: block;
            text-align: center;
            width: 100%;
            height: 100%;
            min-width: 220px;
            line-height: 50px;
            border-radius: 25px;
            background-color: $blue;
            position: absolute;
            top: 0;
            left: 0;
            color: #fff;
            opacity: 1;
            transition: all .8s;

            @include sp {
                // // width: calc(430vw / 7.5);
                // // height: calc(80vw / 7.5);
                // line-height: 50px;
                // border-radius: calc(40vw / 7.5);
            }

            &::after {
                content: "";
                position: absolute;
                width: 16px;
                height: 14px;
                top: 50%;
                transform: translateY(-50%);
                right: 10.8249%;
                background-repeat: no-repeat;
                background-size: contain;
            }

            .arr  {
                position: absolute;
                width: 16px;
                height: 13px;
                top: 50%;
                transform: translateY(-50%);
                right: 10.8249%;
            }

            .hr-mark {
                position: absolute;
                width: 14px;
                top: 50%;
                left: 12%;
                transform: translateY(-50%);
            }
        }
    
        .btn-more.df {
            @include MontB;
            font-size: 1.2rem;
            letter-spacing: .03em;
        }
    
        .btn-more.hov {
            background-color: $navy;
            @include NotoR;
            font-size: 1.3rem;
            letter-spacing: .04em;
            opacity: 0;
        }

        .btn-more.en {
            @include MontB;
            font-size: 1.2rem;
            letter-spacing: .03em;
        }
        .btn-more.ja {
            @include NotoR;
            font-size: 1.3rem;
            letter-spacing: .04em;
        }
        .btn-more.light:hover {
            background-color: $navy;
        }
    }

}

.case-desc-title {

    .case-title {
        @include NotoM;
        font-size: 2rem;
        color: $deep_gray;
        transition: all .8s;
        line-height: 1.4;

        &.culture {
            font-size: 1.4rem;
            line-height: 1.8;
        }

        @include tabMid {
            font-size: calc(28vw / 7.5);
        }

        .cate-link {
            color: $deep_gray;

            &:hover {
                color: #000;
            }
        }
    }
}
.case-desc-tag {

    .case-tags {
        margin-top: 15px;
        transition: all .8s;

        input {
            visibility: hidden;
            opacity: 0;
            width: 0;
            height: 0;
            padding: 0;
            font-size: 0;
        }

        .tag {
            @include NotoM;
            font-size: 1.3rem;
            color: #b5b5b5;
            margin-right: 10px;
            padding: 0;
            cursor: pointer;

            &:hover {
                color: $blue;
            }
        }
    }
}


.btn-box:hover {

    .btn-more.df {
        opacity: 0;
    }

    .btn-more.hov {
        opacity: 1;
    }
}
main {

    .main-main {
        margin-bottom: 316px;
        position: relative;
    
        @include sp {
            margin-bottom: calc(598vw / 7.5);
        }
    }
}



.main-page-top {
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 100;

    &.back-on {
        visibility: hidden;
    }

    &__inner {
        width: 100%;
        height: 100%;
        position: relative;

        &.inside {
            padding-top: calc(45vw / 14.4);
            padding-bottom: calc(45vw / 14.4);
            width: 92.3611%;
            margin: auto;
            
        }

        .main-video {
            width: 100%;
            height: 100%;
            position: relative;
            overflow: hidden;

            &::before {
                @include navyCover;
                z-index: 2;
            }

            .yt-video {
                position: relative;
                z-index: 1;
                transform: scale(1.5);

                #player {
                    
                }

            }
        }
    }
}

#player {
    transform: scale(1.4);

    @include mp {
        transform: scale(2);
    }
    @include sp {
        transform: scale(6);
    }
}

.main-top-body {
    position: absolute;
    top: 30vh;
    left: 0;
    width: 100%;
    text-align: center;
    z-index: 2;

    @include sp {
        top: calc(400vw / 7.5);
    }

    &.recruit {
        top: 38vh;
    }

    .main-msg {
        @include NotoB;
        font-size: calc(70vw / 14.4);
        color: #fff;
        line-height: 1.3;
        width: 86.6666%;
        margin: 0 auto calc(70vw / 14.4) auto;


        @include sp {
            font-size: calc(44vw / 7.5);
            width: 100%;
            margin-bottom: calc(82vw / 7.5);
        }

        &.recruit {
            @include MontB;
            font-size: 80px;
            letter-spacing: .04em;
        }

        span {
            display: block;
            @include oswaldBold;
            font-size: 2.2rem;
            letter-spacing: .03em;
            line-height: 1.3;
            margin-top: calc(30vw / 14.4);

            @include sp {
                font-size: calc(24vw / 7.5);
            }
        }
    }
    
    .main-txt {
        @include NotoB;
        font-size: 1.6rem;
        line-height: 2.2;
        color: #fff;
        width: 90%;
        margin: auto;

        @include sp {
            font-size: calc(24vw / 7.5);
            width: 80%;
            margin: auto;
        }
    }
}

.main-top-brank {
    height: 100vh;
    // margin-bottom: calc(45vw / 14.4);
}

.main-content {
    background-color: #fff;
    position: relative;
    z-index: 300;

    &.top {
        z-index: 100;
    }
}

// パンくずナビ
.bread-nav {
    padding-top: calc(40vw / 14.4);
    padding-bottom: calc(20vw / 14.4);
    width: 90%;
    margin: auto;

    &__inner {
        max-width: 1080px;
        margin: auto;

        #bread_crumb {

            ul {
                display: flex;
                justify-content: flex-start;
                flex-wrap: wrap;
            }

            li {
                display: flex;
                align-items: center;
                @include NotoB;
                font-size: 1.1rem;
                color: $deep_gray;

                @include tabMid {
                    line-height: 1.4;
                }
    

                &::after {
                    content: ">";
                    display: block;
                    margin: 0 10px;
                    color: $deep_gray;
                }

                &:nth-last-child(1) {

                    &::after {
                        display: none;
                    }
                }
                
                a {
                    @include NotoB;
                    font-size: 1.1rem;
                    color: $deep_gray;
                }
            }
        }
    }
}

.section {

    .section-head {
        padding: 60px 0 40px 0;

        @include tab {
            padding: calc(50vw /7.5) 0 calc(50vw /7.5) 0;
        }
        @include sp {
            padding: calc(60vw /7.5) 0 calc(60vw /7.5) 0;
        }

        .section-title {
            text-align: center;
            @include NotoB;
            font-size: 1.6rem;
            letter-spacing: .1em;

            @include tabMid {
                font-size: calc(30vw / 7.5);
                line-height: 1.4;
            }

            @include sp {
                font-size: 1.6rem;
            }

            span{
                display: block;
                @include MontB;
                font-size: 1.1rem;
                margin-top: 1rem;
                text-transform: uppercase;

                @include sp {
                    font-size: 1.1rem;
                }
            }
        }
    }
}

.page-content {
    @include pageWidth;
    margin: auto;
}

.section-article {
    background-color: $bg_gray;

    &.single {
        padding-bottom: 50px;

        @include tabMid {
            padding-bottom: 0;
        }
    }
    
    &__inner {
        display: flex;

        @include tab {
            flex-direction: column;
        }

        .article-part {
            width: 50%;

            @include tab {
                width: 100%;
            }

            .section-head {
                padding: 50px 0 50px 47px;

                    @include tab {
                        padding: calc(50vw / 9.6) 0 calc(50vw / 9.6) calc(45vw / 9.6);
                    }
                    @include sp {
                        padding: calc(60vw /7.5) 0 calc(60vw /7.5) calc(45vw /7.5);
                    }

                .section-title {
                    color: #fff;
                    text-align: left;
                }
            }

            .article-list {
                
                &__inner {
                    display: flex;
                    flex-wrap: wrap;

                    .article-content {
                        display: block;
                        position: relative;
                        height: 50%;
                        width: 50%;
                        cursor: pointer;

                        @include sp {
                            width: 100%;
                        }

                        .article-img-box {
                            width: 100%;
                            padding-top: 83%;
                            position: relative;

                            &::before {
                                position: absolute;
                                content: '';
                                top: 0;
                                bottom: 0;
                                width: 100%;
                                height: 100%;
                                background-color: rgba(0,0,0,0.3);
                                background-repeat: no-repeat;
                                background-size: cover;
                                z-index: 1;
        
                            }

                            img {
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }
    
                        .content-hover {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            background-color: rgba(0,0,0,0.7);
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            display: none;
                            opacity: 0;
                            transition: all .5s;
                            z-index: 2;
    
                            .hover-circle {
                                width: 37.5%;
                                padding-top: 37.5%;
                                position: relative;
    
                                &__inner {
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    width: 100%;
                                    height: 100%;
                                    border-radius: 50%;
                                    background-color: $blue;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
    
                                    .circle-content {
    
                                        .hover-arr {
                                            display: block;
                                            margin: 0 auto 10px auto;
                                            width: 18px;
                                            height: 16px;
                                        }
    
                                        .circle-txt {
                                            @include MontB;
                                            color: #fff;
                                            font-size: calc(13vw / 14.4);
                                            letter-spacing: .03em;
                                        }
                                    }
                                }
                            }
                        }
    
                        &:hover {
                            .content-hover {
                                display: flex;
                                animation: hover-fade-in .5s forwards;
                            }
                        }
    
                        &::before {
                            position: absolute;
                            content: '';
                            top: 0;
                            bottom: 0;
                            width: 100%;
                            height: 100%;
                            background-color: rgba(0,0,0,0.3);
                            background-repeat: no-repeat;
                            background-size: cover;
    
                        }
    
                        img {
                            width: 100%;
                            object-fit: cover;
                            vertical-align: bottom;
                        }
    
                        .article-info {
                            position: absolute;
                            bottom: calc(30vw / 14.4);
                            left: 50%;
                            transform: translateX(-50%);
                            width: 85%;
                            z-index: 2;
    
    
                            &-top {
                                @include MontB;
                                color: $blue;
                                font-size: 1.2rem;
                                letter-spacing: .03em;
                                margin-bottom: 8px;
                            }
    
                            .article-title {
                                @include NotoM;
                                color: #fff;
                                font-size: calc(14vw / 14.4);
                                line-height: 1.86;

                                @include tab {
                                    font-size: calc(19vw / 9.6);
                                }

                                @include sp {
                                    font-size: calc(24vw / 6);
                                }
                                span {
                                    display: block;
                                }
                            }
                        }
                    }
                }
            }
        }
        .article-part.article-pick {

            @include tab {
                margin-bottom: calc(70vw / 9.6);
            }


            .section-head {
                background-color: $navy;   
            }
        }

        .article-part.article-relate {

            .section-head {
                background-color: $blue;   
            }
        }
    }

    .btn-area {
        background-color: $bg_gray;
    }
}

.company-info-container {
    margin-bottom: 70px;

    @include tabMid {
        margin-bottom: 40px;
    }

    @include sp {
        margin-bottom: 20px;
    }

    .compan-info__inner {
        padding: 35px;
        background-color: $bg_gray;
        display: flex;
        justify-content: space-between;

        @include sp {
            padding: 20px;
        }


        .company-info {
            width: 77%;

            .compnay-name {
                @include NotoB;
                font-size: 1.8rem;
                letter-spacing: .04em;
                color: #000;
                margin-bottom: 25px;

                @include sp {
                    font-size: calc( 28vw  / 7.5);
                    line-height: 1.4;
                    margin-bottom: calc( 24vw  / 7.5);
                }
            }

            .text-body {
                @include NotoR;
                font-size: 1.2rem;
                color: $deep_gray;
                line-height: 1.8;
                margin-bottom: 25px;
            }

            .info-box {
                @include NotoR;
                font-size: 1.2rem;
                color: $deep_gray;

                @include tabMid {
                    width: calc(90vw - 70px);
                }

                @include sp {
                    width: calc(90vw - 40px);
                }

                .info-table {
                    display: flex;

                    @include sp {
                        margin-bottom: 8px;
                    }
        
                    .info-name {
                        width: 14%;
                        line-height: 1.8;

                        @include tabMid {
                            width: 20%;
                        }

                        @include sp {
                            width: 25%;
                        }
                    }
                    .info-txt {
                        width: 86%;
                        line-height: 1.4;

                        @include tabMid {
                            width: 80%;
                        }
                    }
                }
            }
        }

        .company-logo-box {
            width: 20%;

            @include tabMid {
                height: 50%;

            }

            img {
                width: 100%;
                object-fit: cover;
            }
        }
    }
}

.move-btn-container {

    margin-bottom: 70px;

    @include sp {
        margin-bottom: calc(50vw / 7.5);
    }

    .move-btn-box {
        width: 210px;
        height: 70px;
        margin: auto;
        display: flex;
        cursor: pointer;

        .move-box {
            display: block;
            position: relative;

            .arr {
                position: absolute;
                display: block;
                width: 16px;
                height: 13px;
                top: 50%;
                transform: translateY(-50%);
            }

            .move-txt {
                position: absolute;
                top: 50%;
                transform: translateY(-50%); 
                @include MontB;
                font-size: 12px;
                color: #fff;
                letter-spacing: .03em;

                &.prev {
                    left: 80px;
                }

                &.next {
                    right: 80px;
                }
            }

        }

        .prev-box {
            width: 33.3333%;
            height: 100%;
            background-color: $navy;
            transition: all .5s;

            &.long {
                width: 66.6666%;
            }

            .arr {
                left: 27px;
            }
        }
        .next-box {
            width: 66.6666%;
            height: 100%;
            background-color: $blue;
            transition: all .5s;

            &.short {
                width: 33.3333%;
            }

            .arr {
                right: 27px;
                
            }
        }
    }
    .back-btn {
        padding-top: 30px;

        .back-top {
            text-align: center;

            .top-link {
                @include MontB;
                font-size: 1.2rem;
                color: $blue;
                letter-spacing: .03em;
            }
        }
    }
}

// インタビュー記事一覧セクション

.recruit-section.section-inter {
    padding-top: 60px;

    @include sp {
        padding-top: 0;
    }

    .recruit-section__inner {

        @include autoWidth;

        .recruit-section-title {
            margin-bottom: 48px;
        }

        .recruit-section-body {
            padding: 0 90px;

            @include sp {
                padding: 0;
            }

            .inter-body-container {
                width: 100%;
                display:flex;
                justify-content: space-between;
                flex-wrap: wrap;


                .inter-content {
                    display: block;
                    width: 29%;
                    cursor: pointer;
                    margin-bottom: 35px;

                    @include sp {
                        width: 49%;
                    }

                    &__inner {
                        position: relative;


                        .inter-img-box {
                            width: 100%;
                            padding-top: 112%;
                            position: relative;

                            img {
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                object-fit: cover;

                            }
                        }

                        .content-hover {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            background-color: rgba(0,0,0,0.7);
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            display: none;
                            opacity: 0;
                            transition: all .5s;
                            z-index: 2;
    
                            .hover-circle {
                                width: 54%;
                                padding-top: 54%;
                                position: relative;
    
                                &__inner {
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    width: 100%;
                                    height: 100%;
                                    border-radius: 50%;
                                    background-color: $blue;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
    
                                    .circle-content {
    
                                        .hover-arr {
                                            display: block;
                                            margin: 0 auto 10px auto;
                                            width: 18px;
                                            height: 16px;
                                        }
    
                                        .circle-txt {
                                            @include MontB;
                                            color: #fff;
                                            font-size: calc(13vw / 14.4);
                                            letter-spacing: .03em;
                                        }
                                    }
                                }
                            }
                        }
    
                        &:hover {
                            .content-hover {
                                display: flex;
                                animation: hover-fade-in .5s forwards;
                            }
                        }
                    }
                    .member-info {
                        padding: 18px;
                        background-color: $blue;
                        width: 92%;
                        max-width: 216px;
        
                        position: relative;
                        margin-top: -17%;
                        z-index: 3;
        
                        @include sp {
                            padding: calc(24vw / 7.5);
                        }
        
                        .member-branch,
                        .member-sort {
                            @include NotoM;
                            font-size: 1.1rem;
                            letter-spacing: .02em;
                            line-height: 1.8;
                            color: #fff;
        
                            @include mp {
                                font-size: calc(13vw / 7.5);
                                line-height: 1.3;
                            }
        
                            @include sp {
                                font-size: calc(18vw / 7.5);
                            }
                        }
                        .member-branch {
                            @include mp {
                                margin-bottom: 5px;
                            }
                        }
                        .member-sort {
                            @include mp {
                                margin-bottom: 7px;
                            }
                        }
        
                        // .member-branch {
                        //     text-align: justify;
                        // }
        
                        .supple {
                            @include NotoM;
                            font-size: 1.1rem;
                            letter-spacing: .02em;
                            line-height: 1.6;
                            color: #fff;
        
                            @include mp {
                                font-size: calc(12vw / 7.5);
                            }
        
                            @include sp {
                                font-size: calc(15vw / 7.5);
                            }
                        }
        
                        .member-name {
                            margin-top: 18px;
                            @include NotoM;
                            font-size: 1.8rem;
                            color: #000;
                            letter-spacing: .1em;
                            line-height: 1.22;
        
                            @include mp {
                                font-size: calc(14vw / 7.5);
                            }
        
                            @include sp {
                                font-size: calc(24vw / 7.5);
                                margin-top: calc(25vw / 7.5);
                            }
        
                            span {
                                margin-top: 6px;
                                display: block;
                                @include MontB;
                                font-size: 1rem;
                                letter-spacing: .03em;
                                color: $deep_gray;
        
                                @include sp {
                                    font-size: calc(15vw / 7.5);
                                }
        
                            }
        
                        }
                    }
                }
            }
        }
    }
}

// 記事ホバーの動き
@keyframes hover-fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

//共通の問い合わせボタン
.common-contact-area {
    background-color: $bg_gray;
    padding: 60px 0;

    @include tabMid {
        padding: calc(60vw / 7.5) 0;
    }

    &__inner {
        @include mainWidth;
        margin: auto;

        @include tab {
            @include spWidth;
        }

        @include tabMid {
            width: 86.6666%;
            margin: auto;
        }
        .btn-box {
            width: 100%;
            max-width: 100%;
    
            &-inner {
    
                .btn-more {

                    @include sp {
                        width: 100%;
                        height: 100%;
                    }

                    img.arr {
                        left: 50%;
                        transform: translate(calc(-50% + 45px), -50%);

                        right: auto;
                        margin-left: 60px;
                    }

                    &::after {
                        display: none!important;
                    }
                }
            }
        }
    }
}

footer {
    height: 316px;
    background-color: #011c30;
    width: 100%;

    &.fixed {
        position: fixed;
        bottom: 0;
        z-index: 50;
    }

    @include sp {
        height: calc(598vw / 7.5);
    }
    .footer__inner {
        padding: 80px 0 40px 0;

        @include sp {
            @include spWidth;
            margin: auto;
            padding: calc(73vw / 7.5) 0 0 0;
        }
        .footer-logo {
            text-align: center;
            margin-bottom: 40px;
            height: 37px;

            @include hoverOpa;

            @include sp {
                text-align: left;
                margin-bottom: calc(47vw / 7.5);
            }
            svg {
                height: 100%;

                @include sp {
                    width: calc(225vw / 6);
                    height: auto;
                }

                polygon,
                path,
                rect {
                    fill: #fff;
                }
            }
        }

        .footer-icons {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 40px;

            &.pc {

                @include sp {
                    display: none;
                }
            }

            &.sp {
                display: none;

                @include sp {
                    display: flex;
                    margin-top: calc( 35vw / 7.5);
                }
            }

            @include sp {
                justify-content: flex-start;
                margin-bottom: calc( 35vw / 7.5 );
            }

            .sns-icon {
                width: 30px;
                margin-right: 15px;
                @include hoverOpa;

                @include sp {
                    width: calc(45vw / 7.5)
                }

                &:nth-last-of-type(1) {
                    margin-right: 0;
                }

                img {
                    width: 100%;
                }
            }
        }

        .footer-nav {
            display: flex;
            justify-content: center;
            margin-bottom: 40px;

            @include sp {
                justify-content: flex-start;
                margin-bottom: calc(58vw / 7.5);
            }
            .f-nav-container {
                display: flex;
                justify-content: center;
                width: 100%;

                .f-nav-part {
                    
                    @include sp {
                        display: flex;
                        flex-direction: column;
                        justify-content: initial;
                        width: 50%;
                    }
                    .f-nav-item {
                        color: #fff;
                        @include RobBlack;
                        font-size: 1.2rem;
                        letter-spacing: 0.12em;
                        margin-right: 20px;
                        cursor: pointer;
                        @include hoverOpa;

                        @include tabMid {
                            margin-right: 10px;
                        }
                        
                        @include sp {
                            font-size: calc(24vw / 7.5);
                            margin-bottom: calc(31vw / 7.5);

                            &:nth-last-child(1) {
                                margin-bottom: 0;
                            }
                        }
                    }
                    &.f-nav-right {
                        .f-nav-item:nth-last-child(1) {
                            margin-right: 0px;
                        }
                    }
                }
            }
        }
        .foooter-line {
            width: 100%;
            height: 2px;
            background-color: #fff;
        }
        // .footer-fb {
        //     color: #fff;
        //     @include MontB;
        //     font-size: calc(19vw / 7.5);
        //     letter-spacing: .1em;
        //     display: flex;
        //     align-items: center;
        //     margin-top: calc(45vw / 7.5);
        //     margin-bottom: calc(45vw / 7.5);
        //     line-height: 2rem;

        //     .fab.fa-facebook {
        //         font-size: calc(34vw / 7.5);
        //         vertical-align: middle;
        //         margin-right: 15px;

        //         &::before {
        //             border-radius: 50%;
        //         }
        //     }
        // }
        .copy {
            color: #fff;
            @include RobBlack;
            font-size: 1rem;
            letter-spacing: 0.12em;
            text-align: center;

            @include sp {
                text-align: left;
                font-size: 1.2rem;
            }

            @include spVer {
                font-size: calc(12vw / 3.7);
            }
        }
    }
}

// コラム（トップページのデザイン）
.section-column.top-page {

    .column-list-def {
        overflow: hidden;

        @include mp {
            display: none;
        }
        
        &__inner {
            display: flex;
            height: calc(600vw / 14.4);

            .column-container-left {
                width: 50%;
                height: 100%;

                .column-content {
                    display: block;
                    width: 100%;
                    padding-top: 83.4722%;
                    position: relative;
                    cursor: pointer;

                    .content-hover {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background-color: rgba(0,0,0,0.7);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        display: none;
                        opacity: 0;
                        transition: all .5s;

                        .hover-circle {
                            width: 37.5%;
                            padding-top: 37.5%;
                            position: relative;

                            &__inner {
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                border-radius: 50%;
                                background-color: $blue;
                                display: flex;
                                justify-content: center;
                                align-items: center;

                                .circle-content {

                                    &::before {
                                        content: "";
                                        display: block;
                                        margin: 0 auto 10px auto;
                                        width: 30px;
                                        height: 26px;
                                        background-image: url(../img/common/arr.png);
                                        background-repeat: no-repeat;
                                        background-size: contain;
                                    }

                                    .circle-txt {
                                        @include MontB;
                                        color: #fff;
                                        font-size: calc(20vw / 14.4);
                                        letter-spacing: .03em;

                                    }
                                }
                            }
                        }
                    }

                    &:hover {
                        .content-hover {
                            display: flex;
                            animation: hover-fade-in .5s forwards;
                        }
                    }

                    &::before {
                        position: absolute;
                        content: '';
                        top: 0;
                        bottom: 0;
                        width: 100%;
                        height: 100%;
                        background-repeat: no-repeat;
                        background-size: cover;
                        z-index: 1;

                    }

                    img {
                        position: absolute;
                        top:0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        vertical-align: bottom;
                    }

                    .column-info {
                        position: absolute;
                        bottom: 30px;
                        left: 6%;
                        z-index: 2;

                        &-top {
                            @include MontB;
                            color: $blue;
                            font-size: 1.2rem;
                            letter-spacing: .03em;
                            margin-bottom: 13px;
                        }

                        .column-title {
                            @include NotoM;
                            color: #fff;
                            font-size: 1.4rem;
                            line-height: 1.86;
                            span {
                                display: block;
                            }
                        }
                    }
                }

            }

            .column-container-right {
                width: 50%;
                display: flex;
                flex-wrap: wrap;

                .column-content {
                    position: relative;
                    height: 50%;
                    width: 50%;
                    cursor: pointer;

                    &:nth-of-type(1) {
                        display: none;
                    }

                    .content-hover {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background-color: rgba(0,0,0,0.7);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        display: none;
                        opacity: 0;
                        transition: all .5s;
                        z-index: 3;

                        .hover-circle {
                            width: 37.5%;
                            padding-top: 37.5%;
                            position: relative;

                            &__inner {
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                border-radius: 50%;
                                background-color: $blue;
                                display: flex;
                                justify-content: center;
                                align-items: center;

                                .circle-content {

                                    &::before {
                                        content: "";
                                        display: block;
                                        margin: 0 auto 10px auto;
                                        width: 18px;
                                        height: 16px;
                                        background-image: url(../img/common/arr.png);
                                        background-repeat: no-repeat;
                                        background-size: contain;
                                    }

                                    .circle-txt {
                                        @include MontB;
                                        color: #fff;
                                        font-size: calc(13vw / 14.4);
                                        letter-spacing: .03em;
                                    }
                                }
                            }
                        }
                    }

                    &:hover {
                        .content-hover {
                            display: flex;
                            animation: hover-fade-in .5s forwards;
                        }
                    }

                    &::before {
                        position: absolute;
                        content: '';
                        top: 0;
                        bottom: 0;
                        width: 100%;
                        height: 100%;
                        background-color: rgba(0,0,0,0.3);
                        background-repeat: no-repeat;
                        background-size: cover;

                    }

                    .column-img-box {
                        width: 100%;
                        padding-top: 83.4722%;
                        position: relative;

                        img {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            vertical-align: bottom;
                        }
                    }

                    .column-info {
                        position: absolute;
                        bottom: calc(30vw / 14.4);
                        left: 50%;
                        transform: translateX(-50%);
                        width: 85%;


                        &-top {
                            @include MontB;
                            color: $blue;
                            font-size: 1.2rem;
                            letter-spacing: .03em;
                            margin-bottom: 8px;
                        }

                        .column-title {
                            @include NotoM;
                            color: #fff;
                            font-size: calc(14vw / 14.4);
                            line-height: 1.86;
                            span {
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }

    .column-list-sub {
        display: none;

        @include mp {
            display: block;
        }
        &__inner {
            margin: auto;

            .column-container {
                display: flex;
                flex-wrap: wrap;

                @include sp {
                    flex-direction: column;
                }
                .column-content {
                    width: 50%;
                    height: calc(360vw / 8);
                    position: relative;

                    @include sp {
                        width: 100%;
                        height: calc(626vw / 7.5);
                    }
                    &::before {
                        position: absolute;
                        content: '';
                        top: 0;
                        bottom: 0;
                        width: 100%;
                        height: 100%;
                        background-color: rgba(0,0,0,0.3);
                        background-repeat: no-repeat;
                        background-size: cover;

                    }
                    
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        vertical-align: bottom;
                    }

                    .column-info {
                        position: absolute;
                        bottom: calc(30vw / 14.4);
                        left: 50%;
                        transform: translateX(-50%);
                        width: 85%;

                        @include sp {
                            bottom: calc(100vw / 14.4);
                        }

                        &-top {
                            @include MontB;
                            color: $blue;
                            font-size: 1.2rem;
                            letter-spacing: .03em;
                            margin-bottom: 8px;

                            @include sp {
                                font-size: calc(22vw / 7.5);
                            }
                        }

                        .column-title {
                            @include NotoM;
                            color: #fff;
                            font-size: calc(24vw / 14.4);
                            line-height: 1.86;

                            @include sp {
                                font-size: calc(28vw / 7.5);
                                letter-spacing: .08em;
                            }
                            span {
                                display: block;
                            }
                        }
                    }

                }
            }
        }
    }
    .btn-area {
        padding: 45px 0;

        @include sp {
            padding: calc(70vw / 7.5) 0;
        }
    }
}

// コラム一覧ページ
.section-column.page-column-list {

    .column-list-def {
        overflow: initial;

        @include mp {
            display: none;
        }
        
        &__inner {
            display: flex;
            height: auto;

            .column-container-left {
                width: 50%;
                height: 100%;
                position: -webkit-sticky;
                position: sticky;
                top: 125px;

                @include tab {
                    top: 134px;
                }

                .column-content {
                    position: relative;
                    cursor: pointer;
                    display: block;

                    &__inner {
                        width: 100%;
                        padding-top: 83.4722%;
                        position: relative;

                        img {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            vertical-align: bottom;
                        }

                    }

                    .content-hover {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background-color: rgba(0,0,0,0.7);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        display: none;
                        opacity: 0;
                        transition: all .5s;
                        z-index: 3;

                        .hover-circle {
                            width: 37.5%;
                            padding-top: 37.5%;
                            position: relative;

                            &__inner {
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                border-radius: 50%;
                                background-color: $blue;
                                display: flex;
                                justify-content: center;
                                align-items: center;

                                .circle-content {

                                    &::before {
                                        display:none;
                                    }

                                    .arr {
                                        content: "";
                                        display: block;
                                        margin: 0 auto 10px auto;
                                        width: 30px;
                                        height: 26px;
                                        position: relative;

                                        img {
                                            width: 30px;
                                            height: 26px;
                                            object-fit: contain;
                                        }
                                    }

                                    .circle-txt {
                                        @include MontB;
                                        color: #fff;
                                        font-size: calc(20vw / 14.4);
                                        letter-spacing: .03em;

                                    }
                                }
                            }
                        }
                    }

                    &:hover {
                        .content-hover {
                            display: flex;
                            animation: hover-fade-in .5s forwards;
                        }
                    }

                    &::before {
                        position: absolute;
                        content: '';
                        top: 0;
                        bottom: 0;
                        width: 100%;
                        height: 100%;
                        background-repeat: no-repeat;
                        background-size: cover;
                        z-index: 1;

                    }

                    .column-info {
                        position: absolute;
                        bottom: 30px;
                        left: 6%;
                        z-index: 2;

                        &-top {
                            @include MontB;
                            color: $blue;
                            font-size: 1.2rem;
                            letter-spacing: .03em;
                            margin-bottom: 13px;
                        }

                        .column-title {
                            @include NotoM;
                            color: #fff;
                            font-size: 1.4rem;
                            line-height: 1.86;
                            span {
                                display: block;
                            }
                        }
                    }
                }

            }

            .column-container-right {
                width: 50%;
                display: flex;
                flex-wrap: wrap;

                .column-content {
                    position: relative;
                    height: auto;
                    width: 50%;
                    cursor: pointer;

                    &__inner {
                        width: 100%;
                        padding-top: 83.4722%;
                        position: relative;

                        &::before {
                            position: absolute;
                            content: '';
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            background-color: rgba(0,0,0,0.2);
                            background-repeat: no-repeat;
    
                        }

                        img {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            vertical-align: bottom;
                        }

                    }

                    .content-hover {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background-color: rgba(0,0,0,0.7);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        display: none;
                        opacity: 0;
                        transition: all .5s;
                        z-index: 3;

                        .hover-circle {
                            width: 37.5%;
                            padding-top: 37.5%;
                            position: relative;

                            &__inner {
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                border-radius: 50%;
                                background-color: $blue;
                                display: flex;
                                justify-content: center;
                                align-items: center;

                                .circle-content {

                                    &::before {
                                        display:none;
                                    }

                                    .arr {
                                        content: "";
                                        display: block;
                                        margin: 0 auto 10px auto;
                                        width: 18px;
                                        height: 16px;
                                        position: relative;

                                        img {
                                            width: 18px;
                                            height: 16px;
                                            object-fit: contain;
                                        }
                                    }

                                    .circle-txt {
                                        @include MontB;
                                        color: #fff;
                                        font-size: calc(13vw / 14.4);
                                        letter-spacing: .03em;
                                    }
                                }
                            }
                        }
                    }

                    &:hover {
                        .content-hover {
                            display: flex;
                            animation: hover-fade-in .5s forwards;
                        }
                    }

                    &::before {
                        position: absolute;
                        content: '';
                        top: 0;
                        bottom: 0;
                        width: 100%;
                        height: 100%;
                        background-color: rgba(0,0,0,0.3);
                        background-repeat: no-repeat;
                        background-size: cover;
                        display: none;
                        z-index: 1;

                    }

                    .column-info {
                        position: absolute;
                        bottom: calc(30vw / 14.4);
                        left: 50%;
                        transform: translateX(-50%);
                        width: 85%;
                        z-index: 2;


                        &-top {
                            @include MontB;
                            color: $blue;
                            font-size: 1.2rem;
                            letter-spacing: .03em;
                            margin-bottom: 8px;
                        }

                        .column-title {
                            @include NotoM;
                            color: #fff;
                            font-size: calc(14vw / 14.4);
                            line-height: 1.86;
                            span {
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }

    .column-list-sub {
        display: none;

        @include mp {
            display: block;
        }
        &__inner {
            margin: auto;

            .column-container {
                display: flex;
                flex-wrap: wrap;

                @include sp {
                    flex-direction: column;
                }
                .column-content {
                    width: 50%;
                    height: calc(360vw / 8);
                    position: relative;

                    @include sp {
                        width: 100%;
                        height: calc(626vw / 7.5);
                    }
                    &::before {
                        position: absolute;
                        content: '';
                        top: 0;
                        bottom: 0;
                        width: 100%;
                        height: 100%;
                        background-color: rgba(0,0,0,0.3);
                        background-repeat: no-repeat;
                        background-size: cover;

                    }
                    
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        vertical-align: bottom;
                    }

                    .column-info {
                        position: absolute;
                        bottom: calc(30vw / 14.4);
                        left: 50%;
                        transform: translateX(-50%);
                        width: 85%;

                        @include sp {
                            bottom: calc(100vw / 14.4);
                        }

                        &-top {
                            @include MontB;
                            color: $blue;
                            font-size: 1.2rem;
                            letter-spacing: .03em;
                            margin-bottom: 8px;

                            @include sp {
                                font-size: calc(22vw / 7.5);
                            }
                        }

                        .column-title {
                            @include NotoM;
                            color: #fff;
                            font-size: calc(24vw / 14.4);
                            line-height: 1.86;

                            @include sp {
                                font-size: calc(28vw / 7.5);
                                letter-spacing: .08em;
                            }
                            span {
                                display: block;
                            }
                        }
                    }

                }
            }
        }
    }
    .btn-area {
        padding: 45px 0;

        @include sp {
            padding: calc(70vw / 7.5) 0;
        }
    }
}

.section-news.page-news-list {

    .news-list {
        @include autoWidth;
        border-top: 1px solid $navy;

        &__inner {
            padding-top: 10px;

            .news-item {
                display: flex;
                align-items: center;
                width: 100%;
                padding: 30px 0;
                @include NotoB;
                font-size: 1.5rem;
                letter-spacing: .08em;
                border-bottom: 1px solid #dfdfdf;

                @include tabMid {
                    flex-wrap: wrap;
                }

                @include sp {
                    font-size: 1.4rem;
                }

                .post-date {
                    width: 120px;
                    line-height: 1.6;
                    color: $deep_gray;
                }

                .post-cate {
                    width: 135px;
                    line-height: 1.6;
                    color: $deep_gray;

                    @include sp {
                        width: 100%;
                    }
                }

                .post-title {
                    width: 80%;
                    color: $blue;
                    line-height: 1.6;
                    cursor: pointer;
                    @include hoverOpa;

                    @include tabMid {
                        width: 100%;
                        margin-top: 8px;
                    }
                }


                
            }
        }

    }
}

// 共通スライダー（オートスライドなし）
.case-slider {
    width: 100%;
    overflow: hidden;

    .case-slide-container {
        overflow-y: visible;
        overflow-x: visible;
        width: 76.5972%;
        position: relative;//numやdescに対して
        height: calc(420vw / 14.4);

        @include sp {
            width: 100%;
            height: calc(290vw / 7.5);
        }

        .case-slide-wrap {

            .case-slide {
                max-height: 1103px;
                height: 100%;

                &.swiper-slide-prev,
                &.swiper-slide-next {
                    opacity: 0.6;
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
        .swiper-button-prev::after,
        .swiper-button-next::after {
            display: none;
        }
        .swiper-button-prev {
            width: calc( 25vw / 14.4 + 45px);
            height: calc( 25vw / 14.4 + 45px);
            background-color: $navy;
            top: auto;
            bottom: 0;
            left: auto;
            right: calc( 25vw / 14.4 + 45px);
            transform: translateY(50%);

            @include sp {
                display: none;
            }

            .arrow {
                display: inline-block;
                position: relative;
                color: #000;

                padding: 0 0 0 16px;
                color: #000;
                vertical-align: middle;
                font-size: 25px;

                &::before,
                &::after {
                    position: absolute;
                    content: "";
                    top: 0;
                    bottom: 0;
                    left: 0;
                    margin: auto;
                    vertical-align: middle;
                }

                &::before {
                left: 0;
                width: 15px;
                height: 1px;
                background: #fff;
                }

                &::after {
                    left: 1px;
                    width: 8px;
                    height: 8px;
                    border-top: 1px solid #fff;
                    border-right: 1px solid #fff;
                    -webkit-transform: rotate(-135deg);
                    transform: rotate(-135deg);
                }
            }
        }
        
        .swiper-button-next {
            width: calc( 25vw / 14.4 + 45px);
            height: calc( 25vw / 14.4 + 45px);
            background-color: $blue;
            right: 0;
            top: auto;
            bottom: 0;
            transform: translateY(50%);

            @include sp {
                display: none;
            }

            .arrow {
                display: inline-block;
                position: relative;
                color: #000;

                padding: 0 0 0 16px;
                color: #000;
                vertical-align: middle;
                font-size: 25px;

                &::before,
                &::after {
                    position: absolute;
                    content: "";
                    top: 0;
                    bottom: 0;
                    left: 0;
                    margin: auto;
                    vertical-align: middle;
                }

                &::before {
                left: 0;
                width: 15px;
                height: 1px;
                background: #fff;
                }

                &::after {
                    left: 5px;
                    width: 8px;
                    height: 8px;
                    border-top: 1px solid #fff;
                    border-right: 1px solid #fff;
                    -webkit-transform: rotate(45deg);
                    transform: rotate(45deg);
                }
            }
        }

        .case-slide-nums {
            position: absolute;
            left: calc( 100% - calc( 50vw / 14.4 + 90px ));
            right: auto;
            bottom: calc(12.5vw / 14.4 + 27.5px);
            z-index: 200;
            white-space: nowrap;

            @include sp {
                width: calc(200vw / 14.4);
            }
            .case-slide-num {
                font-size: calc(9vw / 14.4 + 15px);
                color: #fff;
                @include MontL;

                .num {
                    display: none;
                    font-size: calc(46vw / 14.4 + 20px);

                    &.active {
                        display: inline-block;
                    }
                }
            }
        }
        .swiper-pagination {
            display: none;
            text-align: right;
            padding-right: 7.5%;
            transform: translateY(360%);

            .swiper-pagination-bullet {
                display: inline-block;
                width: 13px;
                height: 13px;
                line-height: 13px;
                border-radius: 50%;
                margin: 0 2px;
                
                &.swiper-pagination-bullet-active {
                    background: $blue;
                }
            }
            
            @include sp {
                display: block;
            }
        }
    }
}

.case-desc-container {
    background-color: #f8f8f8;
}

.case-desc-area {
    @include mainWidth;
    margin: auto;
    padding-top: calc( 12.5vw / 14.4 + 27.5px);
    padding-bottom: 70px;
    border-bottom: 1px solid #e8e8e8;
    cursor: pointer;

    display: none;

    &.active {
        display: block;
    }

    &.culture {
        border-bottom: none;
    }

    @include sp {
        @include spWidth;
    }
    .case-desc-top {
        padding-top: 15px;

        @include sp {
            padding-top: 30px;
        }

        .case-name {
            color: $blue;
            @include NotoM;
            font-size: 1.3rem;
            letter-spacing: .04em;
            line-height: 1.4;
            transition: all .8s;
            margin-bottom: 10px;

            &.culture {
                font-size: 1.8rem;
                margin-bottom: 25px;
            }
        }
    }
}

.srvs-links {
    background-color: $bg_gray;

    &__inner {
        @include pageWidth;
        margin: auto;
        padding: 17px 0 60px 0;
        display: flex;

        @include tab {
            flex-direction: column;
            padding: 12px 0 calc(40vw / 7.5) 0;
        }

        .srv-part {
            position: relative;
            width: 50%;

            @include tab {
                width: 100%;
            }

            &:nth-child(1) {
                @include tab {
                    margin-bottom: calc(40vw /7.5);
                }
            }


            .service-img-box {
                position: relative;
                height: 220px;
    
                @include tabMid {
                    height: calc(220vw /7.5);
                }
    
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
    
                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: rgba(14,76,89,0.3);
                }
            }

            .service-name {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: #fff;
                @include NotoM;
                font-size: 2.2rem;
                letter-spacing: .1em;
                text-transform: uppercase;
                text-align: center;

                @include tab {
                    font-size: calc( 25vw / 7.5 );
                }

                @include sp {
                    font-size: calc( 28vw / 7.5 );
                }

                span {
                    display: block;
                    @include MontB;
                    font-size: 1.2rem;
                    color: $blue;
                    margin-top: 15px;
                    letter-spacing: .03em;
                }
            }
        }

    }
}

.hide-sp {
    @include sp {
        display: none!important;
    }
}

.hide-pc {
    display: none!important;
    @include sp {
        display: block!important;
    }
}
span.hide-pc {
    display: none!important;
    @include sp {
        display: inline!important;
    }
}

.show-sp {
    display: none!important;
    
    @include sp {
        display: block!important;
    }
}
.only-pc {
    display: block!important;
    
    @include mp {
        display: none!important;
    }
}

.hide-tabMid {
    display: block!important;
    
    @include tabMid {
        display: none!important;
    }
}
.show-tabMid {
    display: none!important;
    
    @include tabMid {
        display: block!important;
    }
}

.hide-tabMid {
    display: block!important;
    
    @include tabMid {
        display: none!important;
    }
}
.show-tabMid {
    display: none!important;
    
    @include tabMid {
        display: block!important;
    }
}

.show-tab-long {
    display: none!important;

    @include tabLong {
        display: block!important;
    }

    @include tabMid {
        display: block!important;
    }
}

.hide-tab-long {
    
    display: block!important;

    @include tabLong {
        display: none!important;
    }
    @include tabMid {
        display: none!important;
    }
}

.hide-spVer {
    display: block;

    @include spVer {
        display: none;
    }
}

.show-spVer {
    display: none;

    @include spVer {
        display: block;
    }
}

@mixin font-th {
    @include NotoR;
    font-size: 1.4rem;
    letter-spacing: .1em;
    line-height: 1.8;
    color: #000;
}

@mixin font-td {
    @include NotoR;
    font-size: 1.4rem;
    letter-spacing: .1em;
    line-height: 1.8;
}

.section-contact {
    
    &__inner {
        @include autoWidth;
        margin: auto;

        @include sp {
            margin-top: 15px;
        }
    }
}

.contact-msg {
    margin-bottom: 30px;

    &-txt {
        @include NotoR;
        font-size: 1.4rem;
        letter-spacing: .1em;
        line-height: 1.8;
        color: $deep_gray;

        .red {
            color: #ff3b3b;
        }
    }
}

.wpcf7 {
    .wpcf7-form-control-wrap.menu-730,
    .wpcf7-form-control-wrap.career-pref {

        &::before {
            content: '';
            position: absolute;
            top: 0px;
            right: 40px;
            width: 8px;
            height: 8px;
            border-top: 1px solid #fff;
            border-right: 1px solid #fff;
            -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
            z-index: 1;

            @include sp {
                right: 20px;
            }
        }
    }

    select.wpcf7-select {
        margin: 0; 
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        width: 100%;
        max-width: 753px;
        height: 51px;
        border-radius: 0;
        background-color: $blue;
        border: none;
        padding: 0 15px;
        cursor: pointer;

        @include NotoR;
        font-size: 1.4rem;
        letter-spacing: .1em;
        line-height: 1.8;
        color: #fff;
        position: relative;

        @include spVer {
            font-size: 1.2rem;
        }

    }

    .contact-body {
        margin-top: 20px;
        padding-bottom: 60px;
        border-bottom: 1px solid $deep_gray;

        @include sp {
            padding-bottom: 25px;
        }

        .contact-inner {
            width: 100%;
            max-width: 753px;
        }

        .row {
            display: flex;
            justify-content: flex-start;
            width: 100%;
            max-width: 753px;
            margin-bottom: 20px;

            @include sp {
                flex-direction: column;
                margin-bottom: 10px;
            }
        }


        .th {
            @include font-th;
            text-align: left;
            width: 26%;
            padding-top: 15px;

            @include sp {
                width: 100%;
                padding: 8px 0;
            }

            span {
                color: #ff0000;
            }
        }

        .td {
            width: 74%;

            @include sp {
                width: 100%;
            }
            input {
                width: 100%;
                height: 51px;
                @include font-td;
                color: #000;
                padding:  0 24px;
                border: 1px solid $deep_gray;

                &::placeholder {
                    color: #c6c6c6;
                }
            }

            textarea {
                width: 100%;
                border: 1px solid $deep_gray;
            }
        }
    }
    .contact-supple {

        .privacy {
            display: flex;
            justify-content: flex-start;
            width: 100%;
            max-width: 753px;
            padding: 60px 0;

            @include tab {
                flex-direction: column;
            }

            @include sp {
                padding: 25px 0;
            }

            .privacy-title {
                width: 26%;
                @include font-th;
                letter-spacing: 0;

                @include tab {
                    width: 100%;
                }

                @include sp {
                    padding-bottom: 8px;
                }
            }

            .privacy-content {
                width: 74%;
                white-space: nowrap;
                @include font-td;
                color: $deep_gray;
                letter-spacing: .02em;

                @include mp {
                    white-space: initial;
                }

                @include tab {
                    width: 100%;
                }
            }
        }

        .privacy-condition {
            width: 100%;
            height: 285px;
            color: $deep_gray;
            border: none;
            background-color: $bg_gray;
            overflow-y: scroll;
            padding: 34px;
        }
    }
    .btn-next {
        padding: 60px 0;

        @include sp {
            padding: 25px 0;
        }

        .btn-box {
            margin: auto;

            .btn-box-inner {

                &::before {
                    content: "";
                    position: absolute;
                    width: 16px;
                    height: 14px;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 10.8249%;
                    background-image: url(../../../img/common/arr.png);
                    background-repeat: no-repeat;
                    background-size: contain;
                    z-index: 1;
                }

                .btn-more {

                    &::after {
                        display: none;
                    }
                }

                input {
                    cursor: pointer;
                }
            }
        }

        
    }
}

.wpcf7-not-valid-tip {
    margin-top: 15px;
    @include font-th;
    color: #ff0000;
    font-size: 1.2rem!important;
}