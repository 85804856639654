// フォント
@import url('https://fonts.googleapis.com/css?family=Oswald:400,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Noto+Sans+JP:400,500,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto:400,900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,700&display=swap');

@mixin oswaldBold {
    font-family: 'Oswald', sans-serif;
    font-weight: 700;
}
@mixin NotoB {
    font-family: 'Noto Sans JP', sans-serif;
    font-weight: 700;
}
@mixin NotoM {
    font-family: 'Noto Sans JP', sans-serif;
    font-weight: 500;
}
@mixin NotoR {
    font-family: 'Noto Sans JP', sans-serif;
    font-weight: 400;
}

@mixin RobBlack {
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
}

@mixin MontB {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
}
@mixin MontR {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
}
@mixin MontL {
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
}

// 幅
@mixin mainWidth {
    width: 90%;
    max-width: 1080px;

}

@mixin autoWidth {
  width: 90%;
  max-width: 1080px;
  margin: auto;
}

@mixin spWidth {
    width: 90%;
}

@mixin pageWidth {
  width: 90%;

  @include tabMid {
    width: 86.666%
  }
}

@mixin innerWidth {
  width: 90%;
  max-width: 890px;
  margin: auto;

  @include tabMid {
    width: 100%;
    max-width: initial;
  }
}

// カラー
$blue: #22a7c3;
$navy: #1e2b69;
$ttlNavy: #1d2974;
$gray: #d3d3d3;
$deep_gray: #6c6c6c;
$bg_gray: #f8f8f8;
$bg_white: #fff;

// Media Queries
$mp: 1180px;//MiniPC
$tab: 960px;//Tablet
$tab_long: 900px;
$tab_mid: 768px;//TabletMiddle
$sp: 600px;//SmartPhone including a side view
$sp_ver: 480px;//SmartPhone Vertical
$sp_small: 380px;//SmartPhone on and before iPhone5s

@mixin mp {
    @media screen and (max-width: ($mp)) {
      @content;
    }
}

@mixin tab {
    @media screen and (max-width: ($tab)) {
      @content;
    }
}

@mixin tabMid {
  @media screen and (max-width: ($tab_mid)) {
    @content;
  }
}

@mixin sp {
  @media screen and (max-width: ($sp)) {
    @content;
  }
}

@mixin spVer {
  @media screen and (max-width: ($sp_ver)) {
    @content;
  }
}

@mixin spSmall {
  @media screen and (max-width: ($sp_small)) {
    @content;
  }
}





@mixin tabLong {
  // 横幅900px以上、縦長
  @media screen and (min-height: ($tab_long)) and (orientation: portrait) {
    @content;
  }
}


// イメージカバー
@mixin navyCover {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(1, 28, 48, 0.5);

}
